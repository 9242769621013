
























































































































import Component, { mixins } from 'vue-class-component'
import api, { ISelfInfo } from '~api'

import store from '~store'
import ActionFormMixin, {IActionError} from '~lib/action_form'

import DepositDownload from '~pages/deposits/download.vue'
import Signature from '~components/signature.vue'
import { Wallet } from 'ethers'
import uuidv4 from '~lib/uuid'
import timeout from '~lib/timeout'

  @Component({ components: { DepositDownload, Signature } })
export default class ViewUser extends mixins(ActionFormMixin) {
    public escrow: ISelfInfo | null = null
    public showInsertDialog = false
    public showDeleteDialog = false

    async mounted () {
      await this.submit()
    }

    get footerProps () {
      return {
        disableItemsPerPage: true,
        itemsPerPageOptions: [20]
      }
    }

    get isCurrentEscrow () {
      return this.$route.params.id === store.currentUser!.id
    }

    get name () {
      return this.escrow!.civility + ' ' + this.escrow!.firstName + ' ' + this.escrow!.lastName.toUpperCase()
    }

    async action () {
      this.escrow = (await api.escrow.get(this.$route.params.id)).data
    }

    public async resetEscrow () {
      await api.escrow.reset(this.escrow!.id)
      await this.goToEscrowList()
    }

    public async goToEscrowList () {
      await this.$router.push({ name: 'escrows list' })
    }

    async showInsertEscrow () {
      this.showInsertDialog = true
    }

    async showDeleteEscrow () {
      if (this.escrow!.ethereumAddress !== null) {
        this.showDeleteDialog = true
      } else {
        await api.escrow.delete(this.escrow!.id, null, null)
        return this.goToEscrowList()
      }
    }

    async onInsertWallet (wallet: Wallet) {
      const uuid = uuidv4()
      await store.insertEscrow(wallet, this.escrow!.id, this.escrow!.ethereumAddress!, uuid)
      window.location.reload()
    }

    async onDeleteWallet (wallet: Wallet) {
      const uuid = uuidv4()
      await store.deleteEscrow(wallet, this.escrow!.id, this.escrow!.ethereumAddress!, uuid)
      await this.goToEscrowList()
      this.showDeleteDialog = false
    }

    public getErrorMessage (e: IActionError) {
      if (e.status === 403) this.$router.push({ name: 'forbidden' })
      if (e.status === 404) this.$router.push({ name: '404' })
      if (e.status === 401) {
        timeout()
        this.$router.push({ name: 'escrows list' })
      }
      return undefined
    }
}



















































































import Component, { mixins } from 'vue-class-component'
import api from '~api'
import ActionFormMixin, { IActionError } from '~lib/action_form'
import timeout from '~lib/timeout'

@Component
export default class AddEscrow extends mixins(ActionFormMixin) {
    public firstName = ''
    public lastName = ''
    public email = ''
    public sshkey = ''
    public civility = ''

    public loadingData = false
    public isEditing = false

    async mounted () {
      if (!this.$route.params.id) {
        return
      }

      this.loadingData = true

      try {
        const escrow = await api.escrow.get(this.$route.params.id)
        this.firstName = escrow.data.firstName
        this.lastName = escrow.data.lastName
        this.civility = escrow.data.civility
        this.email = escrow.data.email
        this.sshkey = escrow.data.sshKey

        this.isEditing = true
      } finally {
        this.loadingData = false
      }
    }

    async action () {
      const escrow = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        sshKey: this.sshkey || null,
        civility: this.civility,
        userRole: 'ESCROWAGENT'
      }
      let newEscrow

      if (this.$route.params.id) {
        newEscrow = (await api.escrow.update(this.$route.params.id, escrow)).data
      } else {
        newEscrow = (await api.escrow.create(escrow)).data
      }

      await this.$router.push({ name: 'escrows view', params: { id: newEscrow.id } })
    }

    public getErrorMessage (e: IActionError) {
      if (e.status === 403) this.$router.push({ name: 'forbidden' })
      if (e.status === 404) this.$router.push({ name: '404' })
      if (e.status === 409) return 'escrow.new.error.duplicate'
      if (e.status === 401) {
        timeout()
        this.$router.push({ name: 'escrows list' })
      }
      return undefined
    }
}

























































import Component, { mixins } from 'vue-class-component'
import store from '~store'
import ActionFormMixin, { IActionError, IActionErrorMessage } from '~lib/action_form'
import { VInputRules } from '~lib/vinput_rules'
import { Prop } from 'vue-property-decorator'
import { Wallet } from 'ethers'

@Component
export default class Signature extends mixins(ActionFormMixin) {
  public password = ''

  @Prop({ type: Boolean, default: false })
  public viewDialog!: boolean

  @Prop({ type: Function, default: false })
  public wallet!: (wallet: Wallet) => Promise<void> | void

  public modalValueChanged (value: boolean) {
    if (!value) this.cancel()
  }

  public cancel (): void {
    this.$emit('cancel')
  }

  public async action () {
    const wallet = await Wallet.fromEncryptedJson(store.currentUser!.ethereumJsonWallet, this.password)
    await this.wallet(wallet)
  }

  protected getErrorMessage (e: IActionError): IActionErrorMessage | void {
    if (e.error instanceof Error && e.error.message === 'invalid password') return 'update_password.error_message.invalid_current_password'
  }

  get rules (): VInputRules {
    return {
      password: [
        (v) => !!v || this.$t('deposits.makeRequest.dialog.rules.password.empty'),
        (v) => v.length >= 8 || this.$t('deposits.makeRequest.dialog.rules.password.short')
      ]
    }
  }
}

// tslint:disable no-console
import Vue from 'vue'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN
const SENTRY_ENV = process.env.SENTRY_ENV

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  })
  console.log('Sentry integration is enabled for ' + process.env.NODE_ENV)
} else {
  console.log('Sentry integration is DISABLED.')
}

export interface UserContext {
  id: string
  email: string
  firstName: string
  lastName: string
}

export function setUserContext (user: UserContext | null): void {
  Sentry.configureScope((scope) => {
    scope.setUser(user ? { email: user.email, id: user.id, username: user.firstName + ' ' + user.lastName } : {})
  })
}

export function captureException (ex: unknown, context: string): void {
  console.error('%s: %o', context, ex)
  Sentry.withScope((scope) => {
    scope.setExtra('capturedExceptionContext', context)
    Sentry.captureException(ex)
  })
}

export function exceptionHandler (context: string): (ex: Error | ErrorEvent | string) => void {
  return (e) => captureException(e, context)
}

export function captureMessage (message: string, level?: Sentry.Severity): void {
  console.error(message)
  Sentry.captureMessage(message, level)
}

import Vue from 'vue'
import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VAvatar,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCardSubtitle,
  VCheckbox,
  VCol,
  VContainer,
  VContent,
  VData,
  VDataFooter,
  VDataIterator,
  VDataTable,
  VDataTableHeader,
  VDialog,
  VDivider,
  VFadeTransition,
  VFileInput,
  VFlex,
  VForm,
  VIcon,
  VImg,
  VLayout,
  VList,
  VListItem,
  VListItemAction,
  VListItemIcon,
  VListItemTitle,
  VListItemAvatar,
  VListItemSubtitle,
  VListItemContent,
  VListGroup,
  VMenu,
  VNavigationDrawer,
  VProgressCircular,
  VProgressLinear,
  VRow,
  VSelect,
  VSlideXTransition,
  VSpacer,
  VTab,
  VTabs,
  VTextarea,
  VTextField,
  VToolbar,
  VToolbarItems,
  VToolbarTitle,
  VTooltip
  // tslint:disable-next-line:ban-ts-ignore
  // @ts-ignore
} from 'vuetify/lib'

// tslint:disable-next-line:ban-ts-ignore
// @ts-ignore
import { Ripple } from 'vuetify/lib/directives'

import locale from '../locale'
import './main.scss'
import { VMain } from 'vuetify/lib/components'

Vue.use(Vuetify, {
  directives: { Ripple },
  components: {
    VAlert,
    VApp,
    VAppBar,
    VAvatar,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VCardSubtitle,
    VCheckbox,
    VCol,
    VContainer,
    VContent,
    VDataTable,
    VDataFooter,
    VDataIterator,
    VDataTableHeader,
    VData,
    VDialog,
    VDivider,
    VFadeTransition,
    VFileInput,
    VFlex,
    VForm,
    VIcon,
    VLayout,
    VList,
    VListItem,
    VListItemAction,
    VListItemIcon,
    VListItemTitle,
    VListItemAvatar,
    VListItemSubtitle,
    VListItemContent,
    VListGroup,
    VNavigationDrawer,
    VMain,
    VMenu,
    VProgressCircular,
    VProgressLinear,
    VSlideXTransition,
    VSpacer,
    VTab,
    VTabs,
    VTextarea,
    VTextField,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VSelect,
    VImg,
    VTooltip,
    VRow
  }
})

export const vuetify = new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#0D47A1',
        secondary: '#424242',
        accent: '#2962FF'
      }
    }
  },
  lang: {
    t: (key: string, ...params: {}[]) => locale.t(key, params) as string
  },
  options: {
    customProperties: true
  }
})
















































































import Component, { mixins } from 'vue-class-component'
import api, { IUserLockedInfo } from '~api'
import ActionFormMixin, { IActionError } from '~lib/action_form'
import timeout from '~lib/timeout'

@Component
export default class ViewRequest extends mixins(ActionFormMixin) {
  public lockedAccountDetail: IUserLockedInfo | null = null
  public showResponseDialog = false
  public accepted: boolean | null = null

  async mounted () {
    await this.submit()
  }

  async action () {
    this.lockedAccountDetail = (await api.locked.get(this.$route.params.id)).data
  }

  async unlockAccount () {
    await api.locked.unlock(this.$route.params.id)
    await this.$router.push({ name: 'locked list' })
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 400) return e.response?.data.message
    if (e.status === 304) return e.response?.data.message
    if (e.status === 401) {
      timeout()
      this.$router.push({ name: 'locked list' })
    }
    return undefined
  }
}

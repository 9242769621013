
















































import Component, { mixins } from 'vue-class-component'
import store from '~store'
import ActionFormMixin, { IActionError, IActionErrorMessage } from '~lib/action_form'
import { VInputRules } from '~lib/vinput_rules'

@Component
export default class LoginConfirm extends mixins(ActionFormMixin) {
  public loginConfirmation = ''
  public valid = false

  get rules (): VInputRules {
    return {
      loginConfirmation: [v => !!v || this.$t('login_confirm.rules.code'),
        v => {
          return (v.length < 30) || this.$t('login_confirm.rules.length')
        }
      ]
    }
  }

  async mounted () {
    this.valid = false
  }

  public async action () {
    await store.loginConfirm(this.$route.params.email, this.$route.params.password, this.loginConfirmation)

    if (store.currentUser!.passwordShouldBeUpdated) {
      await this.$router.push({ name: 'password_update' })
      return
    }

    if (store.currentUser!.role === 'BENEFICIARY' || store.currentUser!.role === 'DEPOSITARY') {
      await this.$router.push({ name: 'repositories list' })
    } else {
      await this.$router.push({ name: 'organizations list' })
    }
  }

  // status values : 400 validation code expired, 406 validation code incorrect, 404 not authorized (or max retries)
  public getErrorMessage (e: IActionError) {
    if (e.status === 409) return 'update_password.error_message.deadlock'
    if (e.status === 406) return 'login.error.credentialsValidationCode'
    if (e.status === 400) { this.$router.push({ name: 'login' }) }
    if (e.status === 404) { this.$router.push({ name: 'login' }) }
    return undefined
  }
}

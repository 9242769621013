

















































































import Component, { mixins } from 'vue-class-component'
import api from '~api'
import ActionFormMixin, {IActionError} from '~lib/action_form'
import timeout from '~lib/timeout'

@Component
export default class AddAdmin extends mixins(ActionFormMixin) {
  public firstName = ''
  public lastName = ''
  public email = ''
  public sshkey = ''
  public civility = ''

  public loadingData = false
  public isEditing = false

  async mounted () {
    if (!this.$route.params.id) {
      return
    }

    this.loadingData = true

    try {
      const admin = (await api.admin.get(this.$route.params.id)).data
      this.firstName = admin.firstName
      this.lastName = admin.lastName
      this.civility = admin.civility
      this.email = admin.email
      this.sshkey = admin.sshKey
      this.civility = admin.civility

      this.isEditing = true
    } finally {
      this.loadingData = false
    }
  }

  async action () {
    const admin = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      sshKey: this.sshkey,
      civility: this.civility,
      userRole: 'ADMIN'
    }
    let newAdmin

    if (this.$route.params.id) {
      newAdmin = (await api.admin.update(this.$route.params.id, admin)).data
    } else {
      newAdmin = (await api.admin.create(admin)).data
    }

    await this.$router.push({ name: 'admins view', params: { id: newAdmin.id } })
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 409) return 'escrow.new.error.duplicate'
    if (e.status === 401) {
      timeout()
      this.$router.push({ name: 'admins list' })
    }
    return undefined
  }
}

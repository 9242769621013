import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fr from './fr.yml'

import format from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import dateFr from 'date-fns/locale/fr'
import parseISO from 'date-fns/parseISO'

Vue.filter('formatTimestamp', (timestamp: number | string) => {
  return format(typeof timestamp === 'number' ? fromUnixTime(timestamp) : parseISO(timestamp), 'PPPpp', { locale: dateFr })
})

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: { fr }
})

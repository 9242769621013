import { Action, ClassyStore, default as ClassyVuex, Mutable, StoreModule } from 'classy-vuex'
import Vue from 'vue'
import Vuex from 'vuex'
import nonceToBytes from '~lib/nonceToBytes'

import { utils, Wallet } from 'ethers'
import api, { IRequest, ISelfInfo } from '~api'
import { setUserContext } from '~lib/sentry'
import uuidv4 from '~lib/uuid'

Vue.use<{}>(Vuex)
Vue.use(ClassyVuex, { Vuex })

@StoreModule
export class Store extends ClassyStore {
  @Mutable public currentUser: ISelfInfo | null = null
  @Mutable public ready = false
  @Mutable public adminContractAddress = ''
  @Mutable public usernameCorrespondanceTable = {}
  @Mutable public projectNamesCorrespondanceTable = {}
  @Mutable public mainDepositary = {}

  get isLoggedIn (): boolean {
    return !!this.currentUser
  }

  @Action
  public async login (email: string, password: string): Promise<void> {
    await api.user.login(email, password)
    try {
      // user is logged in for admin and escrow
      this.currentUser = (await api.user.info()).data
      setUserContext(this.currentUser)
    } catch (e) {
      // user is not logged in if not admin or escrow
      setUserContext(undefined)
    }
  }

  @Action
  public async loginConfirm (email: string, password: string, code: string): Promise<void> {

    await api.user.loginConfirm(email, password, code)
    this.currentUser = (await api.user.info()).data
    setUserContext(this.currentUser)
  }

  @Action
  public async updatePassword (newPassword: string, address: string, encryptedWallet: string): Promise<void> {
    this.currentUser = await api.user.updatePassword(newPassword, address, encryptedWallet)
    setUserContext(this.currentUser)
  }

  @Action
  public async insertUser (wallet: Wallet, userId: string, userAddress: string, nonce: string, contractAddress: string, code: number): Promise<void> {
    const signature = await wallet.signMessage(
      utils.arrayify(
        utils.solidityKeccak256(
          ['address', 'bool', 'address', 'bytes16', 'uint256'],
          [userAddress, true, contractAddress, nonceToBytes(nonce), code]
        )
      )
    )

    await api.user.validate_user(userId, nonce, signature)
  }

  @Action
  public async deleteUser (wallet: Wallet, userId: string, userAddress: string, nonce: string, contractAddress: string, code: number): Promise<void> {
    const signature = await wallet.signMessage(
        utils.arrayify(
            utils.solidityKeccak256(
                ['address', 'bool', 'address', 'bytes16', 'uint256'],
                [userAddress, false, contractAddress, nonceToBytes(nonce), code]
            )
        )
    )

    await api.user.delete(userId, nonce, signature)
  }

  @Action
  public async insertAdmin (wallet: Wallet, userId: string, userAddress: string, nonce: string): Promise<void> {
    const signature = await wallet.signMessage(
      utils.arrayify(
        utils.solidityKeccak256(
          ['address', 'uint256', 'address', 'bytes16', 'uint256'],
          [userAddress, 1, this.adminContractAddress, nonceToBytes(nonce), 0]
        )
      )
    )

    await api.admin.validate_admin(userId, nonce, signature)
  }

  @Action
  public async insertEscrow (wallet: Wallet, userId: string, userAddress: string, nonce: string): Promise<void> {
    const signature = await wallet.signMessage(
      utils.arrayify(
        utils.solidityKeccak256(
          ['address', 'uint256', 'address', 'bytes16', 'uint256'],
          [userAddress, 2, this.adminContractAddress, nonceToBytes(nonce), 0]
        )
      )
    )

    await api.escrow.validate_escrow(userId, nonce, signature)
  }

  @Action
  public async deleteAdmin (wallet: Wallet, userId: string, userAddress: string, nonce: string): Promise<void> {
    const signature = await wallet.signMessage(
        utils.arrayify(
            utils.solidityKeccak256(
                ['address', 'uint256', 'address', 'bytes16', 'uint256'],
                [userAddress, 0, this.adminContractAddress, nonceToBytes(nonce), 0]
            )
        )
    )

    await api.admin.delete(userId, nonce, signature)
  }

  @Action
  public async deleteEscrow (wallet: Wallet, userId: string, userAddress: string, nonce: string): Promise<void> {
    const signature = await wallet.signMessage(
      utils.arrayify(
        utils.solidityKeccak256(
          ['address', 'uint256', 'address', 'bytes16', 'uint256'],
          [userAddress, 0, this.adminContractAddress, nonceToBytes(nonce), 0]
        )
      )
    )

    await api.escrow.delete(userId, nonce, signature)
  }

  @Action
  public async registerAccessRequest (wallet: Wallet, nonce: string, address: string): Promise<void> {
    const signature = await wallet.signMessage(
      utils.arrayify(
        utils.solidityKeccak256(['address', 'bytes16', 'uint256'], [address, nonceToBytes(nonce), 3])
      )
    )

    await api.deposits.registerAccessRequest(this.currentUser!.organization!.id, nonce, signature)
  }

  @Action
  public async acceptRequest (id: string, request: IRequest, wallet: Wallet): Promise<void> {
    const requesterRole = request.requester.role
    const targetAddress = request.ethereumAddress
    const contractAddress = request.organization.contractAddress
    const nonce = uuidv4()
    let message

    switch (request.type) {
      case 'ADD':
        message = utils.solidityKeccak256(
          ['address', 'bool', 'address', 'bytes16', 'bytes16', 'uint256'],
          [targetAddress, true, contractAddress, nonceToBytes(request.nonce), nonceToBytes(nonce), requesterRole === 'ESCROWAGENT' ? 11 : 1])
        break
      case 'REMOVE':
        message = utils.solidityKeccak256(
          ['address', 'bool', 'address', 'bytes16', 'bytes16', 'uint256'],
          [targetAddress, false, contractAddress, nonceToBytes(request.nonce), nonceToBytes(nonce), requesterRole === 'ESCROWAGENT' ? 11 : 1])
        break
      case 'ACCESS':
        message = utils.solidityKeccak256(
          ['address', 'bytes16', 'bytes16', 'uint256'],
          [contractAddress, nonceToBytes(request.nonce), nonceToBytes(nonce), requesterRole === 'ESCROWAGENT' ? 13 : 3])
        break
    }

    const signature = await wallet.signMessage(utils.arrayify(message))

    await api.requests.update(id, { accepted: true , nonce, signature })
  }

  @Action
  public async rejectRequest (id: string): Promise<void> {
    await api.requests.update(id, { accepted: false , nonce: null, signature: null })
  }

  @Action
  public async getDecryptedWallet (password: string): Promise<Wallet> {
    return Wallet.fromEncryptedJson(this.currentUser!.ethereumJsonWallet, password)
  }

  @Action
  public async init (): Promise<void> {
    if (this.ready) return

    try {
      this.adminContractAddress = (await api.user.contract()).data
      if (api.user.hasSession) {
        this.currentUser = (await api.user.info()).data
        setUserContext(this.currentUser)
      }
    } catch (e) {
      api.user.dropSession()
      this.currentUser = null
      setUserContext(null)
      throw e
    } finally {
      this.ready = true
    }
  }

  @Action
  public async logout (): Promise<void> {
    await api.user.logout()
    this.currentUser = null
    setUserContext(null)
  }

  @Action
  public async getCorrespondanceTable (): Promise<void> {
    this.usernameCorrespondanceTable = (await api.user.getUsernames()).data
  }

  @Action
  public async getProjectNames (): Promise<void> {
    this.projectNamesCorrespondanceTable = (await api.event.projectNames()).data
  }

  @Action
  public async fmainDepositary (): Promise<void> {
    if (store.currentUser?.role === 'DEPOSITARY') {
      this.mainDepositary = (await api.user.getDepositaryPermissions(this.currentUser!.id)).data
    }
  }

}

const store = new Store()
export default store

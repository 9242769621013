






































































import Component, { mixins } from 'vue-class-component'
import api, { IUserInfo } from '~api'
import { DataTableHeader } from 'vuetify/types'
import ActionFormMixin, { IActionError } from '~lib/action_form'
import timeout from '~lib/timeout'

@Component
export default class Users extends mixins(ActionFormMixin) {
  public users: IUserInfo[] | null = []

  get headers (): DataTableHeader[] {
    return [
      {
        text: this.$t('user.list.name') as string,
        value: 'description',
        sortable: false
      },
      {
        text: this.$t('user.list.email') as string,
        value: 'siren'
      },
      {
        text: this.$t('user.list.role') as string,
        value: 'createdAt'
      }
    ]
  }

  get footerProps () {
    return {
      disableItemsPerPage: true,
      itemsPerPageOptions: [20]
    }
  }

  mounted () {
    this.submit()
  }

  async action () {
    this.users = (await api.user.list()).data
  }

  public role (userRole: string) {
    return this.$t('user.role.' + userRole.toLowerCase())
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 500) return 'user.new.error.conflit'
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      return 'error.timeout'
    }
    return undefined
  }
}

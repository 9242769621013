














import Vue from 'vue'
import Component from 'vue-class-component'
import store from '~store'

@Component
export default class PendingAccess extends Vue {
  get isRequestPending () {
    return store.currentUser!.userPendingRequest
  }

  get hasOrga () {
    return !!store.currentUser!.organization
  }
}

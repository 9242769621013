














































































import Component, { mixins } from 'vue-class-component'
import api, { IAccessEventDetails, ISelfInfo } from '~api'
import { downloadBlob } from '~lib/download'
import ActionFormMixin, {IActionError} from '~lib/action_form'
import store from '~store'
import timeout from '~lib/timeout'

@Component
export default class EventView extends mixins(ActionFormMixin) {
  public event: IAccessEventDetails | null = null
  public user: ISelfInfo | null = null
  public projectNamesMap: {} | undefined

  async mounted () {
    await this.submit()
  }

  async downloadPDF () {
    const result = (await api.event.pdf(this.$route.params.id)).data
    downloadBlob(result, 'event-' + this.$route.params.id + '.pdf')
  }

  async action () {
    this.event = (await api.event.get(this.$route.params.id)).data
    this.user = (await api.user.getFromEthAddr(this.event.beneficiary)).data
    await store.getProjectNames()
    this.projectNamesMap = store.projectNamesCorrespondanceTable
  }

  public showUser () {
    if (this.user != null) {
      this.$router.push({ name: 'users view', params: { id: this.user.id } })
    }
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      this.$router.push({ name: 'events' })
    }
    return undefined
  }
}







































import Vue from 'vue'
import Component from 'vue-class-component'

import SideBar from '~layout/sidebar.vue'
import store from '~store'
import { getToken } from '~api'
import { iTimeout } from '~lib/timeout'

@Component({
  methods: { iTimeout },
  components: { SideBar }
})
export default class Main extends Vue {
  public timerActif = false
  public timer = ''
  public timeout = false

  mounted () {
    this.check()
  }

  get isLoggedIn () { return store.isLoggedIn }
  get ready () { return store.ready }
  get shouldShowAppBar () { return !this.$route.meta.noAppBar }

  async check () {
    setInterval(() => {
      if (getToken()) {
        this.session()
      }
    }, 1000)
  }

  public async session (): Promise<void> {
    if (this.timerActif) {
      return
    }
    if (getToken() === 'header.payload.signature' || getToken() === null) {
      return
    }
    const timestampToken = JSON.parse(atob(getToken().split('.')[1])).exp * 1000
    const timestampNow = new Date().valueOf()
    const duration = Math.abs(timestampToken - timestampNow)
    this.parseMillisecondsIntoReadableTime(duration)
    setTimeout(() => {
      this.timeout = true
    }, duration)
  }

  private parseMillisecondsIntoReadableTime (milliseconds: number): void {
    this.timerActif = true

    // Get hours from milliseconds
    const hours = milliseconds / (1000 * 60 * 60)
    const absoluteHours = Math.floor(hours)
    const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours

    // Get remainder from hours and convert to minutes
    const minutes = (hours - absoluteHours) * 60
    const absoluteMinutes = Math.floor(minutes)
    const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes

    // Get remainder from minutes and convert to seconds
    const seconds = (minutes - absoluteMinutes) * 60
    const absoluteSeconds = Math.floor(seconds)
    const s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds

    this.timer = h + ':' + m + ':' + s
  }
}

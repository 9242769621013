import Vue from 'vue'
import VueRouter, { RawLocation, Route } from 'vue-router'
import { setLogoutHandler, UserRole } from '~api'

import store from '~store'

import Login from '~pages/login.vue'
import UpdateKeys from '~pages/update_keys.vue'
import UpdatePassword from '~pages/update_password.vue'
import LoginConfirm from '~pages/login_confirm.vue'

import DepositsList from '~pages/deposits/list.vue'
import DepositsNew from '~pages/deposits/new.vue'
import DepositsView from '~pages/deposits/view.vue'

import RepositoriesList from '~pages/repositories/list.vue'
import RepositoriesNew from '~pages/repositories/new.vue'

import OrganizationsList from '~pages/organizations/list.vue'
import OrganizationsNew from '~pages/organizations/new.vue'
import OrganizationsView from '~pages/organizations/view.vue'

import UsersList from '~pages/users/list.vue'
import UsersNew from '~pages/users/new.vue'
import UsersView from '~pages/users/view.vue'

import AdminList from '~pages/admins/list.vue'
import AdminNew from '~pages/admins/new.vue'
import AdminView from '~pages/admins/view.vue'

import EscrowList from '~pages/escrows/list.vue'
import EscrowNew from '~pages/escrows/new.vue'
import EscrowView from '~pages/escrows/view.vue'

import RequestList from '~pages/requests/list.vue'
import RequestView from '~pages/requests/view.vue'

import Events from '~pages/events/list.vue'
import EventView from '~pages/events/view.vue'

import PendingAccess from '~pages/pending_access.vue'

import AccountsLocked from '~pages/locked/list.vue'
import AccountsLockedView from '~pages/locked/view.vue'

import NotFound from '~pages/errors/404.vue'
import Forbidden from '~pages/errors/forbidden.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes: [
    { path: '/', redirect: '/login' },

    { path: '/login', name: 'login' , component: Login, meta: { public: true } },
    {
      path: '/update_password',
      name: 'password_update' ,
      component: UpdatePassword,
      meta: { noAppBar: true, universal: true, allowPending: true }
    },
    {
      path: '/login_confirm',
      name: 'login_confirm' ,
      component: LoginConfirm,
      meta: { noAppBar: true, universal: true, allowPending: true, public: true }
    },
    {
      path: '/update_keys',
      name: 'keys_update',
      component: UpdateKeys,
      meta: { universal: true, allowPending: true }
    },
    { path: '/pending_access', name: 'pending_access' , component: PendingAccess, meta: { universal: true, allowPending: true } },

    { path: '/accounts_locked', name: 'locked list', component: AccountsLocked, meta: { admin: true } },
    { path: '/accounts_locked/:id', name: 'locked view', component: AccountsLockedView, meta: { admin: true } },

    { path: '/repositories', name: 'repositories list' , component: RepositoriesList },
    { path: '/repositories/new', name: 'repositories new' , component: RepositoriesNew },
    { path: '/repositories/:repositoryId/edit', name: 'repositories edit' , component: RepositoriesNew },

    { path: '/repositories/:repositoryId/deposits/', name: 'deposits list' , component: DepositsList },
    { path: '/repositories/:repositoryId/deposits/new', name: 'deposits new' , component: DepositsNew },
    { path: '/repositories/:repositoryId/deposits/:id', name: 'deposits view' , component: DepositsView, meta: { universal: true } },

    { path: '/events', name: 'events' , component: Events },
    { path: '/events/:id', name: 'events view' , component: EventView },

    { path: '/organizations', name: 'organizations list' , component: OrganizationsList, meta: { admin: true } },
    { path: '/organizations/new', name: 'organizations new' , component: OrganizationsNew, meta: { admin: true } },
    { path: '/organizations/:id/edit', name: 'organizations edit' , component: OrganizationsNew, meta: { admin: true } },
    { path: '/organizations/:id', name: 'organizations view' , component: OrganizationsView, meta: { admin: true } },

    { path: '/admin/deposits/new', name: 'admin deposits new' , component: DepositsNew, meta: { admin: true } },
    { path: '/admin/deposits/:id', name: 'admin deposits view' , component: DepositsView, meta: { admin: true } },

    { path: '/users', name: 'users list' , component: UsersList },
    { path: '/users/new', name: 'users new' , component: UsersNew },
    { path: '/users/:id', name: 'users view' , component: UsersView, meta: { universal: true } },
    { path: '/users/:id/edit', name: 'users edit' , component: UsersNew },

    { path: '/admins', name: 'admins list' , component: AdminList, meta: { admin: true } },
    { path: '/admins/new', name: 'admins new' , component: AdminNew, meta: { admin: true } },
    { path: '/admins/:id', name: 'admins view' , component: AdminView, meta: { admin: true } },
    { path: '/admins/:id/edit', name: 'admins edit' , component: AdminNew, meta: { admin: true } },

    { path: '/escrows', name: 'escrows list' , component: EscrowList, meta: { admin: true } },
    { path: '/escrows/new', name: 'escrows new' , component: EscrowNew, meta: { admin: true } },
    { path: '/escrows/:id', name: 'escrows view' , component: EscrowView, meta: { admin: true } },
    { path: '/escrows/:id/edit', name: 'escrows edit', component: EscrowNew, meta: { admin: true } },

    { path: '/requests', name: 'requests list' , component: RequestList, meta: { admin: true } },
    { path: '/requests/:id', name: 'requests view' , component: RequestView, meta: { admin: true } },
    // { path: '*', component: NotFound, meta: { public: true } }

    { path: '/forbidden', name: 'forbidden', component: Forbidden , meta: { noAppBar: true } },

    { path: '/404', name: '404', component: NotFound , meta: { noAppBar: true } },
    { path: '*', redirect: '/404' , meta: { noAppBar: true } }
  ]
})

// tslint:disable-next-line:no-any
export function getParentMeta (key: string, route: Route): any | null {
  const found = route.matched.slice().reverse().find((record) => typeof record.meta[key] !== 'undefined')
  if (!found) return null
  return found.meta[key]
}

router.beforeEach((_to, _from, next) => store.init()
    .then(next)
    .catch(() => next({ name: 'login' }))
)

function defaultRouteFor (user: { role: UserRole }): RawLocation {
  if (user.role === 'ADMIN' || user.role === 'ESCROWAGENT') return { name: 'organizations list' }
  return { name: 'repositories list' }
}

router.beforeEach((to, from, next) => {
  const isPublic = getParentMeta('public', to)

  if (!store.isLoggedIn && !isPublic) {
    const fromPath = from.fullPath === '/' ? window.location.pathname : from.fullPath
    return next({ name: 'login', params: { from: fromPath } })
  }

  if (store.currentUser) {
    if (isPublic) return next(defaultRouteFor(store.currentUser))
    const isAllowedPending = getParentMeta('allowPending', to)
    if (!isAllowedPending && store.currentUser.userShouldBeInserted) return next({ name: 'pending_access' })
    if (to.name === 'pending_access' && !store.currentUser.userShouldBeInserted) return next(defaultRouteFor(store.currentUser))
    const isAdmin = getParentMeta('admin', to)
    const isUniversal = getParentMeta('universal', to)
    if (!isUniversal && !(store.currentUser.role === 'ADMIN' || store.currentUser.role === 'ESCROWAGENT') && isAdmin) return next({ name: 'repositories list' })
    if (!isUniversal && (store.currentUser.role === 'ADMIN' || store.currentUser.role === 'ESCROWAGENT')
        && !(isAdmin || isPublic)) return next({ name: 'organizations list' })
  }

  return next()
})

setLogoutHandler(async () => {
  const fromPath = router.currentRoute.fullPath === '/' ? window.location.pathname : router.currentRoute.fullPath
  store.currentUser = null
  await router.push({ name: 'login', params: { from: fromPath } })
})

export default router


































import Component, { mixins } from 'vue-class-component'
import ActionFormMixin from '~lib/action_form'
import api, { IDeposit } from '~api'
import { Prop, Watch } from 'vue-property-decorator'
import timeout from '~lib/timeout'

@Component
export default class DepositsTable extends mixins(ActionFormMixin) {
  @Prop({ type: String, required: true })
  public repositoryId!: string

  @Prop({type: String, required: true})
  public filter = 'done'

  public deposits: IDeposit[] | null = []
  public depositsFilter: IDeposit[] | undefined | null = []

  @Watch('filter')
  onFilterChange (newValue: string) {
    if (newValue === 'loading') {
      this.depositsFilter = this.deposits?.filter(deposits => { return deposits.commitHash === newValue })
    } else if (newValue === 'error') {
      this.depositsFilter = this.deposits?.filter(deposits => { return deposits.commitHash === newValue })
    } else if (newValue === 'null') {
      this.depositsFilter = this.deposits
    } else {
      this.depositsFilter = this.deposits?.filter(deposits => { return !(deposits.commitHash === 'loading' || deposits.commitHash === 'error') })
    }
  }

  navigateToDeposit (id: string) {
    this.$router.push({ name: 'deposits view', params: { id } })
  }

  getStyle(item: IDeposit) {
    if (item.commitHash === 'loading' || item.commitHash === 'error') {
      return {
        cursor: 'default',
        opacity: '0.5'
      };
    }
    return {
      cursor: 'pointer'
    };
  },

  mounted () {
    this.submit()
  }

  async action () {
    this.deposits = (await api.deposits.list(this.repositoryId)).data
    this.depositsFilter = this.deposits?.filter(deposits => { return !(deposits.commitHash === 'loading' || deposits.commitHash === 'error') })
  }

  get headers (): DataTableHeader[] {
    return [
      {
        text: this.$t('deposits.source') as string,
        value: 'depositSource',
        sortable: false,
        disabled: false
      },
      {
        text: this.$t('deposits.description') as string,
        value: 'description',
        sortable: false,
        disabled: false
      },
      {
        text: this.$t('deposits.createdAt') as string,
        value: 'createdAt',
        disabled: false
      }
    ]
  }

  get footerProps () {
    return {
      disableItemsPerPage: true,
      itemsPerPageOptions: [20]
    }
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      return 'error.timeout'
    }
    return undefined
  }
}

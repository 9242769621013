/**
 * This module is a thin wrapper to help manage LocalStorage, if available
 */

/**
 * Checks if the given Storage feature is present and available (i.e. not disabled by browser). From
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
 */
function storageAvailable (): boolean {
  const storage = localStorage
  try {
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
  }
}

/**
 * If the local storage feature is available or not.
 */
const LOCAL_STORAGE_AVAILABLE = storageAvailable()
const LOCAL_STORAGE_PREFIX = 'confiance4/'

const eventListeners: {[key: string]: EventListener[] | undefined} = {}

if (LOCAL_STORAGE_AVAILABLE) {
  window.addEventListener('storage', (e) => {
    const listeners = eventListeners[e.key!]
    if (!listeners) return
    listeners.forEach((listener) => listener(e))
  })
}

/**
 * Retrieves an item from the browser's local storage.
 * If the key does not exist, or if local storage is not available, the given defaultValue is returned.
 */
export function get (key: string, defaultValue: string | null = null): string | null {
  if (!LOCAL_STORAGE_AVAILABLE) return defaultValue
  const value = localStorage.getItem(LOCAL_STORAGE_PREFIX + key)
  if (value === null) return defaultValue
  return value
}

/**
 * Puts an item into the browser's LocalStorage.
 * This method fails silently if local storage is not available, or if the setItem() method failed.
 */
export function set (key: string, value: string | null | undefined): void {
  if (!LOCAL_STORAGE_AVAILABLE) return
  try {
    localStorage.setItem(LOCAL_STORAGE_PREFIX + key, value || '')
  } catch (e) {
    //
  }
}

/**
 * Removes an item from the browser's LocalStorage.
 * This method fails silently if local storage is not available, or if the setItem() method failed.
 */
export function remove (key: string): void {
  localStorage.removeItem(LOCAL_STORAGE_PREFIX + key)
}

/**
 * Watches a specific local storage key for events
 */
export function watch (key: string, callback: EventListener): void {
  if (!LOCAL_STORAGE_AVAILABLE) return
  let listeners = eventListeners[LOCAL_STORAGE_PREFIX + key]
  if (!listeners) {
    listeners = [callback]
    eventListeners[LOCAL_STORAGE_PREFIX + key] = listeners
  } else {
    listeners.push(callback)
  }
}

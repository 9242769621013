

























































































































































































import Component, { mixins } from 'vue-class-component'
import api, { IDepositaryPermissions, IRepository, ISelfInfo } from '~api'

import ActionFormMixin, { IActionError } from '~lib/action_form'

import DepositDownload from '~pages/deposits/download.vue'
import store from '~store'
import uuidv4 from '~lib/uuid'
import Signature from '~components/signature.vue'
import { Wallet } from 'ethers'
import timeout from '~lib/timeout'

@Component({ components: { DepositDownload, Signature } })
export default class ViewUser extends mixins(ActionFormMixin) {
  public user: ISelfInfo | null = null
  public showDialog = false
  public showInsertDialog = false
  public signature = ''
  public errorVisible = false
  public actionLoading = false
  public depositaryPermissions: IDepositaryPermissions | null = null
  public repositories: IRepository[] | null = []
  dialogOpen = false

  async mounted () {
    await this.submit()
  }

  get footerProps () {
    return {
      disableItemsPerPage: true,
      itemsPerPageOptions: [20]
    }
  }

  displayValue (value: string) {
    return this.$t('user.type.' + value.toLowerCase())
  }

  displayDate (value: number) {
    const date = new Date(value * 1000)

    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
  }

  openDialog () {
    this.dialogOpen = true
  }

  get name () {
    return this.user!.civility + ' ' + this.user!.firstName + ' ' + this.user!.lastName.toUpperCase()
  }

  async action () {
    this.user = (await api.user.get(this.$route.params.id)).data
    this.depositaryPermissions = (await api.user.getDepositaryPermissions(this.$route.params.id)).data
    this.repositories = (await api.repositories.list()).data
  }

  get showRepositoriesAccess () : IRepository[] {
    let rep: IRepository[] = []
    if (this.depositaryPermissions && this.repositories) {
      rep = this.repositories?.filter(x => this.depositaryPermissions?.repositoriesList.includes(x.id))
            .sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0))
    }
    return rep
  }

  get isCurrentUser () {
    return this.$route.params.id === store.currentUser!.id
  }

  public role (userRole: string) {
    return this.$t('user.role.' + userRole.toLowerCase())
  }

  public async resetUser () {
    this.errorVisible = false
    this.actionLoading = true
    try {
      await api.user.reset(this.user!.id)
    } catch (e) {
      const code = e?.response?.status

      if (code === 409) {
        this.errorMessage = 'user.view.errors.deadlock'
        this.errorVisible = true
        return
      } else {
        throw e
      }
    } finally {
      this.actionLoading = false
    }
    await this.goToUserList()
  }

  public async goToUserList () {
    await this.$router.push({ name: 'users list' })
  }

  async showDeleteUser () {
    if (this.user!.ethereumAddress !== null) {
      this.showDialog = true
    } else {
      await api.user.delete(this.user!.id, null, null)
      return this.goToUserList()
    }
  }

  public userContractActionCode (): number {
    switch (this.user!.role) {
      case 'BENEFICIARY':
        return store.currentUser!.role === 'ESCROWAGENT' ? 11 : 1
      case 'DEPOSITARY':
        return 2
      default:
        throw new Error(`Unknown user role: ${this.user!.role}`)
    }
  }

  async showInsertUser () {
    this.showInsertDialog = true
  }

  async onWallet (wallet: Wallet) {
    const nonce = uuidv4()
    const contractAddress = store.currentUser!.organization!.contractAddress
    await store.deleteUser(wallet, this.user!.id, this.user!.ethereumAddress!, nonce, contractAddress, this.userContractActionCode())
    this.showDialog = false
    return this.goToUserList()
  }

  async onInsertWallet (wallet: Wallet) {
    const nonce = uuidv4()
    const contractAddress = store.currentUser!.organization!.contractAddress
    await store.insertUser(wallet, this.user!.id, this.user!.ethereumAddress!, nonce, contractAddress, this.userContractActionCode())
    this.showInsertDialog = false
    await this.submit()
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      this.errorVisible = true
      timeout()
      this.$router.push({ name: 'users list' })
    }
    return undefined
  }
}

import axios, { AxiosResponse, CancelToken } from 'axios'

import * as LocalStorage from '~lib/localstorage'

const BASE_URI = process.env.NODE_ENV === 'development' ? process.env.API_URI! : window.location.origin + '/api'
const LOCALSTORAGE_TOKEN = 'authorizationToken'

const unauthenticatedInstance = axios.create({ baseURL: BASE_URI, withCredentials: true })
const authenticatedInstance = axios.create({ baseURL: BASE_URI, withCredentials: true })

let currentToken: string | null = LocalStorage.get(LOCALSTORAGE_TOKEN)

authenticatedInstance.interceptors.request.use((config) => {
  if (currentToken) config.headers = { Authorization: 'Bearer ' + currentToken, ...config.headers }
  return config
})

authenticatedInstance.interceptors.response.use(async (value) => {
  if (value.status === 401) {
    dropSession()
    await logoutHandler()
  }
  return value
})

export function getToken (): string | null {
  return currentToken
}

let logoutHandler: () => Promise<void> = () => Promise.resolve()

export function setLogoutHandler (handler: () => Promise<void>): void {
  logoutHandler = handler
}

export interface IOrganization {
  id: string,
  name: string,
  siren: string,
  contractAddress: string
}

export interface IOrganizationUpdatable {
  name: string,
  siren: string
}

export interface IOrganizationCreate {
  name: string,
  siren: string,
  mainDepositary: {
    firstName: string
    lastName: string
    civility: string
    email: string
  },
  signatory: {
    firstName: string
    lastName: string
    civility: string
    email: string
  }
}

export type UserRole = 'ADMIN' | 'BENEFICIARY' | 'DEPOSITARY' | 'ESCROWAGENT'
export type RequestType = 'ADD' | 'REMOVE' | 'ACCESS'
export type DepositSource = 'WEB_UI' | 'DIRECT_GIT'
export type AccessType = 'FULL' | 'RESTRICTED' | 'MAIN'

export interface ICommitDetails {
  date: Date
  diff: IModification[]
  author: string
  commit: string
  message: string
  email: string
}

export interface IModification {
  changeType: string
  oldPath: string
  newPath: string
}

export interface IDepositaryPermissions {
  id: string,
  access: AccessType,
  repositoriesList: []
}

export interface IUserInfo {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  civility: string,
  organization?: IOrganization,
  role: UserRole
}

export interface ISelfInfo extends IUserInfo {
  userShouldBeInserted: boolean,
  userPendingRequest: boolean,
  passwordShouldBeUpdated: boolean,
  sshKey: string,
  ethereumJsonWallet: string,
  ethereumAddress: string | null,
  lastLoginAt: string,
  deletedAt: string
}

export interface IUserUpdatable {
  firstName: string,
  lastName: string,
  email: string,
  sshKey?: string | null,
  civility: string,
  typesOfRights: string,
  rightsOnRepo?: [] | null,
}

export interface ISignatory {
  firstName: string,
  lastName: string,
  email: string
}

export interface IUserCreatable extends IUserUpdatable {
  userRole: string
}

export interface IUserLockedInfo extends ISelfInfo {
  lockedAt: string
}

export interface IRepository {
  id: string
  description: string,
  txHash: string,
  organization: IOrganization,
}

export interface IRepositoryCreate {
  description: string
}

export interface IDocumentSigning {
  signed: boolean
}

export interface IDeposit {
  id: string,
  hash: string,
  shortMessage: string,
  fullMessage: string,
  commitedAt: string,
  txHash: string,
  rawCommitterName: string,
  rawCommitterEmail: string,
  organization: IOrganization,
  user: IUserInfo | null,
  depositSource: DepositSource
}

export interface IAccessEvent {
  id: string
  startDate: string
  endDate: string
  beneficiary: string
}

export interface ITransactionData {
  transactionHash: string
  transactionIndex: number
  blockHash: string
  blockNumber: number
  cumulativeGasUsed: number
  gasUsed: number
  contractAddress: string | null
  root: string | null
  status: string
  from: string
  to: string
  logs: ITransactionLogs[]
  logsBloom: string
  revertReason: string | null
  type: string | null
  effectiveGasPrice: string
  statusOK: boolean
  blockNumberRaw: string
  transactionIndexRaw: string
  gasUsedRaw: string
  cumulativeGasUsedRaw: string
}

export interface ITransactionLogs {
  removed: boolean
  logIndex: number
  transactionIndex: number
  transactionHash: string
  blockHash: string
  blockNumber: number
  address: string
  data: string
  type: string | null
  topics: string[]
  transactionIndexRaw: string
  blockNumberRaw: string
  logIndexRaw: string
}

export interface IAccessEventDetails {
  id: string
  beneficiary: string
  beneficiaryNonce: string
  beneficiarySignature: string
  escrowAgent: string
  escrowAgentNonce: string
  escrowAgentSignature: string
  windowStartsAt: string
  windowEndsAt: string
  projectName: string
}

export interface IRequest {
  nonce: string,
  signature: string,
  requester: ISelfInfo,
  target: ISelfInfo,
  organization: IOrganization,
  ethereumAddress: string | null,
  type: RequestType
  createdAt: string
}

export type IRequestParams = {
  nonce: string,
  signature: string,
  accepted: true
} | {
  nonce: null,
  signature: null,
  accepted: false
}

export interface IAuthDownload {
  auth: string
}

export interface IAuthGit {
  auth: string
}

function dropSession (): void {
  currentToken = null
  LocalStorage.set(LOCALSTORAGE_TOKEN, null)
}

export type ProgressHandler = (onUploadProgress: ProgressEvent<XMLHttpRequestEventTarget>) => void

export default {
  deposits: {
    get: (repositoryId: string, id: string) => authenticatedInstance.get<{}, AxiosResponse<IDeposit>>(`/deposits/${repositoryId}/${id}`),
    signed: (repositoryId: string, id: string) => authenticatedInstance.get<{}, AxiosResponse<IDocumentSigning>>(`/deposits/${repositoryId}/${id}/signed`),
    downloadUri: (id: string) => `${BASE_URI}/deposits/${id}/download`,
    download: (repositoryId: string, id: string, onDownloadProgress?: ProgressHandler) =>
        authenticatedInstance.get<{}, AxiosResponse<Blob>>(`/deposits/${repositoryId}/${id}/download`, { responseType: 'blob', onDownloadProgress }),
    checkdownload: (repositoryId: string, id: string) =>
        authenticatedInstance.get<{}, AxiosResponse<IAuthDownload>>(`/deposits/${repositoryId}/${id}/checkdownload`),
    list: (repositoryId: string) => authenticatedInstance.get<{}, AxiosResponse<IDeposit[]>>(`/deposits/${repositoryId}/`),
    txDetails: (repositoryId: string, id: string) => authenticatedInstance.get<{}, AxiosResponse<ITransactionData>>(`/deposits/${repositoryId}/${id}/txInfo`),
    commitDetails: (repositoryId: string, id: string) => authenticatedInstance.get<{}, AxiosResponse<ICommitDetails>>(`/deposits/${repositoryId}/${id}/commitInfo`),
    create: (
        repositoryId: string,
        deposit: { description: string, version?: string | null },
        depositContents: File,
        onUploadProgress?: ProgressHandler,
        cancelToken?: CancelToken
    ) => {
      const formData = new FormData()

      formData.append('file', depositContents)

      const meta = new Blob([JSON.stringify(deposit)], { type: 'application/json' })
      formData.append('meta', meta)

      return authenticatedInstance.post<FormData, AxiosResponse<IDeposit>>(`/deposits/${repositoryId}/`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress,
        cancelToken
      })
    },
    pdf: (repositoryId: string, id: string, onDownloadProgress?: ProgressHandler) =>
        authenticatedInstance.get<{}, AxiosResponse<Blob>>(`/deposits/${repositoryId}/${id}.pdf`, { responseType: 'blob', onDownloadProgress }),
    registerAccessRequest: (organizationId: string, nonce: string, signature: string) =>
        authenticatedInstance.post('/deposits/ask_access', { organizationId, nonce, signature })
  },
  repositories: {
    get: (id: string) => authenticatedInstance.get<{}, AxiosResponse<IRepository>>(`/repositories/${id}`),
    list: () => authenticatedInstance.get<{}, AxiosResponse<IRepository[]>>('/repositories/'),
    create: (repository: IRepositoryCreate) =>
        authenticatedInstance.post<IRepositoryCreate, AxiosResponse<IRepository>>('/repositories/', repository),
    update: (id: string, repository: IRepositoryCreate) =>
        authenticatedInstance.put<IRepositoryCreate, AxiosResponse<IRepository>>(`/repositories/${id}`, repository),
    gitCloneUri: (id: string) => authenticatedInstance.get<{}, AxiosResponse<string>>(`/repositories/${id}/git_clone_uri`),
    checkGitCloneUri: (id: string) => authenticatedInstance.get<{}, AxiosResponse<IAuthGit>>(`/repositories/${id}/check_git_clone_uri`)
  },
  organizations: {
    get: (id: string) => authenticatedInstance.get<{}, AxiosResponse<IOrganization>>('/organizations/' + id),
    getUser: (idOrga: string, idUser: string) =>
        authenticatedInstance.get<{}, AxiosResponse<ISelfInfo>>('/organizations/' + idOrga + '/users/' + idUser),
    getDeposit: (idOrga: string, idRepository: string, idDeposit: string) =>
        authenticatedInstance.get<{}, AxiosResponse<IDeposit>>('/organizations/' + idOrga + '/deposits/' + idRepository + '/' + idDeposit),
    getRepository: (idOrga: string, idRepository: string) =>
        authenticatedInstance.get<{}, AxiosResponse<IRepository>>('/organizations/' + idOrga + '/repository/' + idRepository),
    list: () => authenticatedInstance.get<{}, AxiosResponse<IOrganization[]>>('/organizations/'),
    create: (organization: IOrganizationCreate) =>
        authenticatedInstance.post<IOrganizationCreate, AxiosResponse<IOrganization>>('/organizations/', organization),
    update: (id: string, organization: IOrganizationUpdatable) =>
        authenticatedInstance.put<IOrganizationUpdatable, AxiosResponse<IOrganization>>('/organizations/' + id, organization),
    getUsers: (idOrga: string) => authenticatedInstance.get<{}, AxiosResponse<IUserInfo[]>>('/organizations/' + idOrga + '/users'),
    renewOrgSmartContract: (idOrga: string) =>
        authenticatedInstance.put<{}, AxiosResponse<IOrganization>>('/organizations/' + idOrga + '/renew_smart_contract')
  },
  admin: {
    get: (id: string) => authenticatedInstance.get<{}, AxiosResponse<ISelfInfo>>('/admins/' + id),
    list: () => authenticatedInstance.get<{}, AxiosResponse<IUserInfo[]>>('/admins/'),
    create: (user: IUserCreatable) =>
        authenticatedInstance.post<IUserCreatable, AxiosResponse<ISelfInfo>>('/admins/', user),
    update: (id: string, user: IUserUpdatable) =>
        authenticatedInstance.put<IUserUpdatable, AxiosResponse<ISelfInfo>>('/admins/' + id, user),
    reset: (id: string) => authenticatedInstance.put<{}, AxiosResponse<ISelfInfo>>('/admins/' + id + '/reset_wallet'),
    delete: (targetUserId: string, nonce: string | null, signature: string | null) =>
        authenticatedInstance.post<{}, AxiosResponse<ISelfInfo>>('/admins/delete', { targetUserId, nonce, signature }),
    validate_admin: (targetUserId: string, nonce: string, signature: string) =>
        authenticatedInstance.post<{}, AxiosResponse<ISelfInfo>>('/admins/validate_admin', { targetUserId, nonce, signature })
  },
  escrow: {
    get: (id: string) => authenticatedInstance.get<{}, AxiosResponse<ISelfInfo>>('/escrows/' + id),
    list: () => authenticatedInstance.get<{}, AxiosResponse<IUserInfo[]>>('/escrows/'),
    create: (user: IUserCreatable) =>
        authenticatedInstance.post<IUserCreatable, AxiosResponse<ISelfInfo>>('/escrows/', user),
    update: (id: string, user: IUserUpdatable) =>
        authenticatedInstance.put<IUserUpdatable, AxiosResponse<ISelfInfo>>('/escrows/' + id, user),
    reset: (id: string) => authenticatedInstance.put<{}, AxiosResponse<ISelfInfo>>('/escrows/' + id + '/reset_wallet'),
    delete: (targetUserId: string, nonce: string | null, signature: string | null) =>
        authenticatedInstance.post<{}, AxiosResponse<ISelfInfo>>('/escrows/delete', { targetUserId, nonce, signature }),
    validate_escrow: (targetUserId: string, nonce: string, signature: string) =>
        authenticatedInstance.post<{}, AxiosResponse<ISelfInfo>>('/escrows/validate_escrow', { targetUserId, nonce, signature })
  },
  user: {
    get: (id: string) => authenticatedInstance.get<{}, AxiosResponse<ISelfInfo>>('/users/' + id),
    getUsernames: () => authenticatedInstance.get<{}, AxiosResponse<JSON>>('/users/eth'),
    getFromEthAddr: (ethAddr: string) => authenticatedInstance.get<{}, AxiosResponse<ISelfInfo>>('/users/eth/' + ethAddr),
    getDepositaryPermissions: (id: string) => authenticatedInstance
        .get<{}, AxiosResponse<IDepositaryPermissions>>('/users/depositary_permissions/' + id),
    list: () => authenticatedInstance.get<{}, AxiosResponse<IUserInfo[]>>('/users/'),
    get hasSession (): boolean { return !!currentToken },
    contract: () => unauthenticatedInstance.get<{}, AxiosResponse<string>>('/auth/contract/'),
    login: (email: string, password: string) => unauthenticatedInstance
        .post<{}, AxiosResponse<{ sessionToken: string }>>('/auth/login', { email, password })
        .then((response) => {
          currentToken = response.data.sessionToken
          LocalStorage.set(LOCALSTORAGE_TOKEN, currentToken)
        }),
    loginConfirm: (email: string, password: string, code: string) => authenticatedInstance
        .post<{}, AxiosResponse<{ sessionToken: string }>>('/auth/login_confirm', { email, password, code })
        .then((response) => {
          currentToken = response.data.sessionToken
          LocalStorage.set(LOCALSTORAGE_TOKEN, currentToken)
        }),
    updatePassword: (password: string, ethereumAddress: string, ethereumJsonWallet: string) => authenticatedInstance
        .post<{}, AxiosResponse<ISelfInfo>>('/auth/password_reset', { password, ethereumAddress, ethereumJsonWallet })
        .then(({ data }) => data),
    info: () => authenticatedInstance.get<{}, AxiosResponse<ISelfInfo>>('/auth/user'),
    dropSession,
    logout: () => {
      const promise = authenticatedInstance.post<void, void>('/auth/logout')
      promise.then(dropSession).catch(dropSession)
      return promise
    },
    validate_user: (targetUserId: string, nonce: string, signature: string) =>
        authenticatedInstance.post<{}, AxiosResponse<ISelfInfo>>('/users/validate_user', { targetUserId, nonce, signature }),
    create: (user: IUserCreatable) =>
        authenticatedInstance.post<IUserCreatable, AxiosResponse<ISelfInfo>>('/users/', user),
    update: (id: string, user: Partial<IUserUpdatable>) =>
        authenticatedInstance.put<IUserUpdatable, AxiosResponse<ISelfInfo>>('/users/' + id, user),
    delete: (targetUserId: string, nonce: string | null, signature: string | null) =>
        authenticatedInstance.post<{}, AxiosResponse<ISelfInfo>>('/users/delete/', { targetUserId, nonce, signature }),
    reset: (id: string) => authenticatedInstance.put<{}, AxiosResponse<ISelfInfo>>('/users/' + id + '/reset_wallet')
  },
  event: {
    get: (id: string) => authenticatedInstance.get<{}, AxiosResponse<IAccessEventDetails>>('/events/accesses/' + id),
    list: () => authenticatedInstance.get<{}, AxiosResponse<IAccessEvent[]>>('/events/accesses'),
    projectNames: () => authenticatedInstance.get<{}, AxiosResponse<Map<string, string>>>('/events/accesses/project-name'),
    pdf: (id: string, onDownloadProgress?: ProgressHandler) =>
        authenticatedInstance.get<{}, AxiosResponse<Blob>>('/events/accesses/' + id + '.pdf', { responseType: 'blob', onDownloadProgress })
  },
  requests: {
    get: (id: string) => authenticatedInstance.get<{}, AxiosResponse<IRequest>>('/requests/' + id),
    list: () => authenticatedInstance.get<{}, AxiosResponse<IRequest[]>>('/requests/'),
    update: (id: string, params: IRequestParams) =>
        authenticatedInstance.put<IUserUpdatable, AxiosResponse<boolean>>('/requests/' + id, params)
  },
  locked: {
    get: (id: string) => authenticatedInstance.get<{}, AxiosResponse<IUserLockedInfo>>('/locked/' + id),
    list: () => authenticatedInstance.get<{}, AxiosResponse<IUserLockedInfo[]>>('/locked/'),
    unlock: (id: string) => authenticatedInstance.post<{}, AxiosResponse<void>>('/locked/' + id + '/unlock')
  }
}















































































































































import Component, { mixins } from 'vue-class-component'
import api from '~api'

import ActionFormMixin, { IActionError } from '~lib/action_form'
import { VInputRules } from '~lib/vinput_rules'
import timeout from '~lib/timeout'

@Component
export default class Organizations extends mixins(ActionFormMixin) {
  public name = ''
  public siren = ''
  public mainDepositaryFirstName = ''
  public mainDepositaryLastName = ''
  public mainDepositaryEmailName = ''
  public mainDepositaryCivility = 'M'
  public signatoryFirstname = ''
  public signatoryLastName = ''
  public signatoryEmailName = ''
  public signatoryCivility = 'M'

  public loadingData = false
  public isEditing = false

  async mounted () {
    if (!this.$route.params.id) {
      return
    }

    this.loadingData = true

    try {
      const organization = await api.organizations.get(this.$route.params.id)
      this.name = organization.data.name
      this.siren = organization.data.siren
      this.signatoryCivility = organization.data.signatories[0]?.civility
      this.signatoryFirstname = organization.data.signatories[0]?.firstName
      this.signatoryLastName = organization.data.signatories[0]?.lastName
      this.signatoryEmailName = organization.data.signatories[0]?.email
      this.isEditing = true
    } finally {
      this.loadingData = false
    }
  }

  get rules (): VInputRules {
    return {
      email: [v => !!v || this.$t('organizations.new.rules.main_depositary_email'),
        v => {
          const pattern = /.+[@].+[.].+/
          return pattern.test(v) || this.$t('organizations.new.rules.main_depositary_emailInvalid')
        }
      ]
    }
  }

  async action () {
    const organization = {
      name: this.name,
      siren: this.siren,
      mainDepositary: {
        firstName: this.mainDepositaryFirstName,
        lastName: this.mainDepositaryLastName,
        email: this.mainDepositaryEmailName,
        civility: this.mainDepositaryCivility
      },
      signatory: {
        firstName: this.signatoryFirstname,
        lastName: this.signatoryLastName,
        email: this.signatoryEmailName,
        civility: this.signatoryCivility
      }
    }
    let newOrganization

    if (this.$route.params.id) {
      newOrganization = (await api.organizations.update(this.$route.params.id, organization)).data
    } else {
      newOrganization = (await api.organizations.create(organization)).data
    }

    await this.$router.push({ name: 'organizations view', params: { id: newOrganization.id } })
  }

  public getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      this.$router.push({ name: 'organizations list' })
    }
    return undefined
  }

  public renewSmartContract () {
    api.organizations.renewOrgSmartContract(this.$route.params.id)
  }
}

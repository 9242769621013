



























































































































































































































































































































import Component, { mixins } from 'vue-class-component'
import api, { ICommitDetails, IDeposit, IDocumentSigning, ITransactionData, IUserInfo } from '~api'
import timeout from '~lib/timeout'

import ActionFormMixin, { IActionError } from '~lib/action_form'

import DepositDownload from '~pages/deposits/download.vue'
import { downloadBlob } from '~lib/download'
import store from '~store'

@Component({ components: { DepositDownload } })
export default class Deposits extends mixins(ActionFormMixin) {
  public deposit: IDeposit | null = null
  public document: IDocumentSigning | null = null
  public loadingPDF: boolean = false
  public txDetails: ITransactionData | null = null
  public commitDetails: ICommitDetails | null = null
  public repositoryName: string | null = null
  public currentUser: IUserInfo | null = null
  txDialogOpen = false
  gitDialogOpen = false

  mounted () {
    this.submit()
  }

  openDialogTx () {
    this.txDialogOpen = true
  }

  openDialogGit () {
    this.gitDialogOpen = true
  }

  get depositId () {
    return this.$route.params.id
  }

  get repositoryId () {
    return this.$route.params.repositoryId
  }

  get isBeneficiaryAndValidated () {
    return store.currentUser!.role === 'BENEFICIARY' && !store.currentUser!.userShouldBeInserted
  }

  async downloadPDF () {
    this.loadingPDF = true
    try {
      const result = (await api.deposits.pdf(this.repositoryId, this.depositId)).data
      downloadBlob(result, 'deposit-' + this.$route.params.id + '.pdf')
    } catch (err) {
      await this.$router.push({ name: 'deposits list' })
    } finally {
      this.loadingPDF = false
    }
  }

  async action () {
    this.currentUser = store.currentUser
    this.deposit = (await api.deposits.get(this.repositoryId, this.depositId)).data
    this.document = (await api.deposits.signed(this.repositoryId, this.depositId)).data
    this.txDetails = (await api.deposits.txDetails(this.repositoryId, this.depositId)).data
    this.commitDetails = (await api.deposits.commitDetails(this.repositoryId, this.depositId)).data
    this.repositoryName = (await api.repositories.get(this.$route.params.repositoryId)).data.description
  }

  protected getErrorMessage (e: IActionError) {
    if (e.status === 403) this.$router.push({ name: 'forbidden' })
    if (e.status === 404) this.$router.push({ name: '404' })
    if (e.status === 401) {
      timeout()
      this.$router.push({ name: 'deposits list' })
    }
    return undefined
  }
}







































































import Component, { mixins } from 'vue-class-component'
import store from '~store'
import ActionFormMixin, { IActionError, IActionErrorMessage } from '~lib/action_form'
import { VInputRules } from '~lib/vinput_rules'
import { Wallet } from 'ethers'

type WalletToSend = { encryptedWallet: string, address: string }

@Component
export default class UpdatePassword extends mixins(ActionFormMixin) {
  public currentPassword = ''
  public newPassword = ''
  public newPasswordConfirmation = ''

  protected checkNewPassword (): boolean {
    const pattern = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{12,}$/

    if (!!this.newPassword && !!this.newPasswordConfirmation) {
      if (pattern.test(this.newPassword)) {
        return this.newPassword === this.newPasswordConfirmation
      }
    } return false
  }

  get shouldProvideCurrentPassword (): boolean {
    return !store.currentUser!.passwordShouldBeUpdated
  }

  get rules (): VInputRules {
    return {
      currentPassword: [
        (v) => !!v || this.$t('update_password.rules.current_password.empty'),
        (v) => v.length >= 12 || this.$t('update_password.rules.current_password.short')
      ],
      newPassword: [
        (v) => !!v || this.$t('update_password.rules.new_password.empty'),
        (v) => v.length >= 12 || this.$t('update_password.rules.new_password.short'),
        v => {
          const pattern = /.*[A-Z].*/
          return pattern.test(v) || this.$t('update_password.rules.new_password.upper')
        },
        v => {
          const pattern = /.*[a-z].*/
          return pattern.test(v) || this.$t('update_password.rules.new_password.lower')
        },
        v => {
          const pattern = /.*\d.*/
          return pattern.test(v) || this.$t('update_password.rules.new_password.number')
        },
        v => {
          const pattern = /.*[^A-Za-z0-9].*/
          return pattern.test(v) || this.$t('update_password.rules.new_password.special')
        }
      ],
      confirmation: [
        (v) => !!v || this.$t('update_password.rules.confirmation.empty'),
        (v) => v === this.newPassword || this.$t('update_password.rules.confirmation.unmatched')
      ]
    }
  }

  private async getWalletToSend (): Promise<WalletToSend> {
    if (store.currentUser!.passwordShouldBeUpdated) {
      const newWallet = Wallet.createRandom()
      return {
        encryptedWallet: await newWallet.encrypt(this.newPassword),
        address: newWallet.address
      }
    }

    const currentWallet = await Wallet.fromEncryptedJson(
      store.currentUser!.ethereumJsonWallet,
      this.currentPassword
    )
    return {
      encryptedWallet: await currentWallet.encrypt(this.newPassword),
      address: currentWallet.address
    }
  }

  protected getErrorMessage (e: IActionError): IActionErrorMessage | void {
    if (e.status === 409) return 'update_password.error_message.deadlock'
    if (e.status === 400) return e.response?.data.message
  }

  public async action () {
    let wallet: WalletToSend | null
    try {
      wallet = await this.getWalletToSend()
    } catch (e: any) {
      if (e.message === 'invalid password') {
        this.errorMessage = this.$t('update_password.error_message.invalid_current_password')
        return
      }
      throw e
    }
    await store.updatePassword(this.newPassword, wallet.address, wallet.encryptedWallet)
    await this.$router.push('/')
  }
}

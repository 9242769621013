

































import Component, { mixins } from 'vue-class-component'
import api, { IUserInfo } from '~api'
import { DataTableHeader } from 'vuetify/types'
import ActionFormMixin, {IActionError} from '~lib/action_form'
import timeout from '~lib/timeout'

  @Component
export default class EscrowAgents extends mixins(ActionFormMixin) {
    public escrows: IUserInfo[] | null = []

    get headers (): DataTableHeader[] {
      return [
        {
          text: this.$t('escrow.list.name') as string,
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('escrow.list.email') as string,
          value: 'email'
        }
      ]
    }

    mounted () {
      this.submit()
    }

    async action () {
      this.escrows = (await api.escrow.list()).data
    }

    get footerProps () {
      return {
        disableItemsPerPage: true,
        itemsPerPageOptions: [20]
      }
    }

    public getErrorMessage (e: IActionError) {
      if (e.status === 403) this.$router.push({ name: 'forbidden' })
      if (e.status === 404) this.$router.push({ name: '404' })
      if (e.status === 401) {
        timeout()
        return 'error.timeout'
      }
      return undefined
    }
}
